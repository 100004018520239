import React from 'react';

import { Meta } from '../components/Meta';
import { MainLayout } from '../components/MainLayout';
import { HeroSection } from '../components/HeroSection';
import { ServiceDetailsSection } from '../components/ServiceDetailsSection';

import HeroImage from '../images/gardeners.webp';
import Vegetable from '../images/vegetable.webp';
import Planting from '../images/planting.webp';
import Winter from '../images/winter.webp';

const heroTitle = "Nantucket's Gardening Experts";
const heroSubtitle = 'Servicing Nantucket for over 20  years.';

const serviceTitle = 'Merlos Gardeners';
const serviceSubtitle =
  'Make your home a special place with our expert gardeners. We can provide and install garden beds, planters, pots, and landscaping design. The Merlos team can give your home a pop of floral color and tasteful green beauty for the Spring, Summer, Fall, and Winter.';
const services = [
  {
    title: 'Vegetable Garden Design',
    description:
      'Interested in having your own vegetable garden? Merlos can design and plant your dream garden for your very own backyard farm-to-table experience.',
    image: Vegetable,
  },
  {
    title: 'Planting & Maintenance',
    description: 'The Merlos garden team will design, install and maintain your pots and planters all summer.',
    image: Planting,
  },
  {
    title: 'Winter Turn Down',
    description:
      'When winter comes, we will cut back  and help protect your plants from deer and the harsh weather so that they will flourish again in the Spring.',
    image: Winter,
  },
];

export default function GardeningPage() {
  return (
    <MainLayout>
      <Meta route="gardening" />
      <HeroSection title={heroTitle} subtitle={heroSubtitle} image={HeroImage} />
      <ServiceDetailsSection title={serviceTitle} subtitle={serviceSubtitle} services={services} />
    </MainLayout>
  );
}
